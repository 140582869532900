<template>
  <div>
    <div class="d-flex justify-content-end">
      <button
        v-if="!showFilter"
        @click="showFilter = true"
        class="d-flex btn btn-info"
      >
        <i class="material-icons-outlined">filter_alt</i> Advanced Filter
      </button>
    </div>
    <div class="text-right mt-4 text-primary">
      <i>{{ count }} products found</i>
    </div>
    <div v-if="showFilter" class="card filter-on-dashboard">
      <div class="card-title px-4 d-flex justify-content-between my-4">
        <h6>Filter</h6>
        <i class="material-icons-outlined d-lg-none" @click="showFilter = false"
          >close</i
        >
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 my-2 col-md-4 col-xl-4 col-lg-3">
            <label for="">Company</label>
            <v-select
              @search="GET_COMPANIES_LIST"
              label="name"
              class="style-chooser"
              @option:selected="SET_SELECTED_COMPANY"
              :value="activeCompany.status ? activeCompany : null"
              :clearable="false"
              placeholder="Search company name"
              :options="companies"
            ></v-select>
          </div>
          <div class="col-12 my-2 col-md-4 col-xl-4 col-lg-3">
            <label for="">Product</label>
            <v-select
              @search="GET_PRODUCT_LIST"
              ref="productSelect"
              label="model"
              class="style-chooser"
              :value="activeProduct.status ? activeProduct : null"
              @option:selected="SET_SELECTED_PRODUCT"
              :clearable="false"
              placeholder="Search Model Number"
              :options="products"
            ></v-select>
          </div>
          <div class="col-12 my-2 col-md-4 col-xl-4 col-lg-3">
            <label for="">Serial Number / Machine Location</label>
            <div class="form-group">
              <input
                class="form-control"
                v-model="search"
                required
                type="text"
                aria-describedby="emailHelp"
                placeholder="Enter Serial Number or Machine Location"
              />
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 d-flex justify-content-end">
            <button
              @click="GET_LIST_OF_PRODUCT(search)"
              class="btn btn-primary btn-lg"
            >
              Apply Filter
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <!-- FILTRELEME ITEMS -->
      <div
        v-if="isSearched"
        class="my-4 d-flex align-items-center"
        style="flex-flow: row wrap"
      >
        <span
          @click="
            activeCompany = { status: false, id: 0 };
            GET_LIST_OF_PRODUCT(search);
          "
          v-if="activeCompany.status"
          class="
            filter-badge
            badge badge-pill
            align-items-center
            badge-warning
            m-2
          "
        >
          Company: {{ activeCompany.name }}
          <i class="ml-2 material-icons-outlined">close</i>
        </span>
        <span
          @click="
            activeProduct = { status: false, id: 0 };
            GET_LIST_OF_PRODUCT(search);
          "
          v-if="activeProduct.status"
          class="
            filter-badge
            badge badge-pill
            align-items-center
            badge-danger
            m-2
          "
        >
          Product: {{ activeProduct.model }}
          <i class="ml-2 material-icons-outlined">close</i>
        </span>
        <span
          @click="
            search = '';
            GET_LIST_OF_PRODUCT(search);
          "
          v-if="search !== ''"
          class="
            filter-badge
            badge badge-pill
            align-items-center
            badge-secondary
            m-2
          "
        >
          S.No / ML: {{ search }}
          <i class="ml-2 material-icons-outlined">close</i>
        </span>
      </div>
    </div>
    <CardTableProducts
      :allItemsCount="count"
      :headers="headers"
      :listOfProducts="listOfProducts"
      :loading="loading"
      :page="page"
      :pages="pages"
      @refresh="GET_LIST_OF_PRODUCT"
      @change="SET_PAGINATION"
    ></CardTableProducts>
  </div>
</template>

<script>
import ProductActions from "../createReport/chooseCompany/chooseProduct/productActions";
import CompanyActions from "../createReport/chooseCompany/companyActions";
import CardTableProducts from "../ui/table/cardTableProducts.vue";
export default {
  mixins: [ProductActions, CompanyActions],
  components: {
    CardTableProducts,
  },
  data() {
    return {
      productDetailModal: false,
      showFilter: false,
      activeBrand: { id: 0 },
      activeComponent: "",
      activeProductDetail: { status: false, companyProduct: { id: -1 } },
      page: 1,
      limit: 10,
      count: 0,
      search: "",
      searchLazy: -1,
      isSearched: false,
      listOfProducts: [],
      loading: true,
      headers: [
        { label: "Product", col: 12, md: 12, lg: 5, xl: 5 },
        { label: "Company", col: 12, md: 12, lg: 3, xl: 3 },
        { label: "Actions", col: 12, md: 12, lg: 4, xl: 4 },
      ],
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.count / 10);
    },
    modalTitle() {
      if (this.activeComponent === "product-history") return "Product History";
      else if (this.activeComponent === "product-update")
        return "Product Update";
      else if (this.activeComponent === "product-reports")
        return "Product Reports";
      else return "";
    },
    modalOkButton() {
      if (this.activeComponent === "product-history") return false;
      else if (this.activeComponent === "product-update") return false;
      else if (this.activeComponent === "product-reports") return false;
      else return false;
    },
  },
  watch: {
    search(data) {
      if (data === "") return;
      if (this.searchLazy !== -1) clearTimeout(this.searchLazy);
      this.searchLazy = setTimeout(() => {
        this.GET_LIST_OF_PRODUCT(data);
      }, 1500);
    },
  },
  methods: {
    SET_PAGINATION(page) {
      this.$router.replace({ query: { page } });
      this.page = page;
      this.GET_LIST_OF_PRODUCT(this.search);
    },
    SET_DETAIL_THEN_PUSH(row, comp) {
      console.log(row);
      this.activeComponent = comp;
      this.activeProductDetail = { ...row, status: true };
      this.productDetailModal = true;
    },
    async GET_LIST_OF_PRODUCT(data = "") {
      if (window.innerWidth < 992) {
        this.showFilter = false;
      }
      if (data !== "") this.isSearched = true;
      this.loading = true;
      const response = await this.$api.get(
        `CompanyProducts?offset=${
          this.page
        }&limit=10&searchKey=${data}&companyId=${
          this.activeCompany.id === -2 || this.activeCompany.id === -1
            ? 0
            : this.activeCompany.id
        }&productId=${this.activeProduct.id}`
      );
      if (response.result === "OK" && response.message === "OK") {
        this.listOfProducts = response.data.items;
        if (response.data.count) this.count = response.data.count;
      } else this.listOfProducts = [];
      this.loading = false;
    },
  },
  mounted() {
    if (window.innerWidth > 992) {
      this.showFilter = true;
    }
    if (this.$route.query.page) this.page = this.$route.query.page;
    this.GET_LIST_OF_PRODUCT();
  },
};
</script>

<style>
</style>