<template>
  <list-of-products />
</template>

<script>
import listOfProducts from "../../components/products/listOfProducts.vue";
export default {
  components: { listOfProducts },
};
</script>

<style>
</style>